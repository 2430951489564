<template>
  <div></div>
</template>
<script>
import { GET } from "@/core/services/store/request.module";
export default {
  name: "UserPermissionList",
  props: {
    permissionDetail: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  methods: {
    getModules() {
      this.$store
        .dispatch(GET, {
          url: "/permission",
        })
        .then(({ data }) => {
          this.items = data;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
  },
  mounted() {
    this.getModules();
    this.permissionDet = this.permissionDetail;
  },
};
</script>
